/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.30.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CategoryListApiModel } from './CategoryListApiModel';
import {
    CategoryListApiModelFromJSON,
    CategoryListApiModelFromJSONTyped,
    CategoryListApiModelToJSON,
} from './CategoryListApiModel';
import type { ProductCardApiModel } from './ProductCardApiModel';
import {
    ProductCardApiModelFromJSON,
    ProductCardApiModelFromJSONTyped,
    ProductCardApiModelToJSON,
} from './ProductCardApiModel';
import type { ProductDetailApiModelDeliveryTime } from './ProductDetailApiModelDeliveryTime';
import {
    ProductDetailApiModelDeliveryTimeFromJSON,
    ProductDetailApiModelDeliveryTimeFromJSONTyped,
    ProductDetailApiModelDeliveryTimeToJSON,
} from './ProductDetailApiModelDeliveryTime';
import type { ProductDownloadApiModel } from './ProductDownloadApiModel';
import {
    ProductDownloadApiModelFromJSON,
    ProductDownloadApiModelFromJSONTyped,
    ProductDownloadApiModelToJSON,
} from './ProductDownloadApiModel';
import type { ProductStickerApiModel } from './ProductStickerApiModel';
import {
    ProductStickerApiModelFromJSON,
    ProductStickerApiModelFromJSONTyped,
    ProductStickerApiModelToJSON,
} from './ProductStickerApiModel';
import type { TechnicalDetailApiModel } from './TechnicalDetailApiModel';
import {
    TechnicalDetailApiModelFromJSON,
    TechnicalDetailApiModelFromJSONTyped,
    TechnicalDetailApiModelToJSON,
} from './TechnicalDetailApiModel';
import type { VideoApiModel } from './VideoApiModel';
import {
    VideoApiModelFromJSON,
    VideoApiModelFromJSONTyped,
    VideoApiModelToJSON,
} from './VideoApiModel';

/**
 * 
 * @export
 * @interface ProductDetailApiModel
 */
export interface ProductDetailApiModel {
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    ean?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    brandLogo?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDetailApiModel
     */
    images?: Array<string> | null;
    /**
     * 
     * @type {VideoApiModel}
     * @memberof ProductDetailApiModel
     */
    video?: VideoApiModel;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    shortDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    longDescription?: string | null;
    /**
     * 
     * @type {Array<TechnicalDetailApiModel>}
     * @memberof ProductDetailApiModel
     */
    technicalDetails?: Array<TechnicalDetailApiModel> | null;
    /**
     * 
     * @type {Array<ProductCardApiModel>}
     * @memberof ProductDetailApiModel
     */
    relatedProducts?: Array<ProductCardApiModel> | null;
    /**
     * 
     * @type {Array<ProductStickerApiModel>}
     * @memberof ProductDetailApiModel
     */
    stickers?: Array<ProductStickerApiModel> | null;
    /**
     * 
     * @type {Array<ProductDownloadApiModel>}
     * @memberof ProductDetailApiModel
     */
    downloads?: Array<ProductDownloadApiModel> | null;
    /**
     * 
     * @type {CategoryListApiModel}
     * @memberof ProductDetailApiModel
     */
    mainCategory?: CategoryListApiModel;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    packagingInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    volumeDiscounts?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    webshopUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    sku?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    mainImage?: string | null;
    /**
     * 
     * @type {ProductDetailApiModelDeliveryTime}
     * @memberof ProductDetailApiModel
     */
    deliveryTime?: ProductDetailApiModelDeliveryTime;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    deliveryText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    brandName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailApiModel
     */
    unitQuantity?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailApiModel
     */
    unit?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailApiModel
     */
    priceExclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailApiModel
     */
    listPriceExclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailApiModel
     */
    priceInclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailApiModel
     */
    listPriceInclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailApiModel
     */
    requiredSaleQuantity?: number | null;
}

/**
 * Check if a given object implements the ProductDetailApiModel interface.
 */
export function instanceOfProductDetailApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductDetailApiModelFromJSON(json: any): ProductDetailApiModel {
    return ProductDetailApiModelFromJSONTyped(json, false);
}

export function ProductDetailApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDetailApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ean': !exists(json, 'ean') ? undefined : json['ean'],
        'brandLogo': !exists(json, 'brandLogo') ? undefined : json['brandLogo'],
        'images': !exists(json, 'images') ? undefined : json['images'],
        'video': !exists(json, 'video') ? undefined : VideoApiModelFromJSON(json['video']),
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'longDescription': !exists(json, 'longDescription') ? undefined : json['longDescription'],
        'technicalDetails': !exists(json, 'technicalDetails') ? undefined : (json['technicalDetails'] === null ? null : (json['technicalDetails'] as Array<any>).map(TechnicalDetailApiModelFromJSON)),
        'relatedProducts': !exists(json, 'relatedProducts') ? undefined : (json['relatedProducts'] === null ? null : (json['relatedProducts'] as Array<any>).map(ProductCardApiModelFromJSON)),
        'stickers': !exists(json, 'stickers') ? undefined : (json['stickers'] === null ? null : (json['stickers'] as Array<any>).map(ProductStickerApiModelFromJSON)),
        'downloads': !exists(json, 'downloads') ? undefined : (json['downloads'] === null ? null : (json['downloads'] as Array<any>).map(ProductDownloadApiModelFromJSON)),
        'mainCategory': !exists(json, 'mainCategory') ? undefined : CategoryListApiModelFromJSON(json['mainCategory']),
        'packagingInfo': !exists(json, 'packagingInfo') ? undefined : json['packagingInfo'],
        'volumeDiscounts': !exists(json, 'volumeDiscounts') ? undefined : json['volumeDiscounts'],
        'webshopUrl': !exists(json, 'webshopUrl') ? undefined : json['webshopUrl'],
        'sku': !exists(json, 'sku') ? undefined : json['sku'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'mainImage': !exists(json, 'mainImage') ? undefined : json['mainImage'],
        'deliveryTime': !exists(json, 'deliveryTime') ? undefined : ProductDetailApiModelDeliveryTimeFromJSON(json['deliveryTime']),
        'deliveryText': !exists(json, 'deliveryText') ? undefined : json['deliveryText'],
        'brandName': !exists(json, 'brandName') ? undefined : json['brandName'],
        'unitQuantity': !exists(json, 'unitQuantity') ? undefined : json['unitQuantity'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'priceExclVat': !exists(json, 'priceExclVat') ? undefined : json['priceExclVat'],
        'listPriceExclVat': !exists(json, 'listPriceExclVat') ? undefined : json['listPriceExclVat'],
        'priceInclVat': !exists(json, 'priceInclVat') ? undefined : json['priceInclVat'],
        'listPriceInclVat': !exists(json, 'listPriceInclVat') ? undefined : json['listPriceInclVat'],
        'requiredSaleQuantity': !exists(json, 'requiredSaleQuantity') ? undefined : json['requiredSaleQuantity'],
    };
}

export function ProductDetailApiModelToJSON(value?: ProductDetailApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ean': value.ean,
        'brandLogo': value.brandLogo,
        'images': value.images,
        'video': VideoApiModelToJSON(value.video),
        'shortDescription': value.shortDescription,
        'longDescription': value.longDescription,
        'technicalDetails': value.technicalDetails === undefined ? undefined : (value.technicalDetails === null ? null : (value.technicalDetails as Array<any>).map(TechnicalDetailApiModelToJSON)),
        'relatedProducts': value.relatedProducts === undefined ? undefined : (value.relatedProducts === null ? null : (value.relatedProducts as Array<any>).map(ProductCardApiModelToJSON)),
        'stickers': value.stickers === undefined ? undefined : (value.stickers === null ? null : (value.stickers as Array<any>).map(ProductStickerApiModelToJSON)),
        'downloads': value.downloads === undefined ? undefined : (value.downloads === null ? null : (value.downloads as Array<any>).map(ProductDownloadApiModelToJSON)),
        'mainCategory': CategoryListApiModelToJSON(value.mainCategory),
        'packagingInfo': value.packagingInfo,
        'volumeDiscounts': value.volumeDiscounts,
        'webshopUrl': value.webshopUrl,
        'sku': value.sku,
        'name': value.name,
        'type': value.type,
        'mainImage': value.mainImage,
        'deliveryTime': ProductDetailApiModelDeliveryTimeToJSON(value.deliveryTime),
        'deliveryText': value.deliveryText,
        'brandName': value.brandName,
        'unitQuantity': value.unitQuantity,
        'unit': value.unit,
        'priceExclVat': value.priceExclVat,
        'listPriceExclVat': value.listPriceExclVat,
        'priceInclVat': value.priceInclVat,
        'listPriceInclVat': value.listPriceInclVat,
        'requiredSaleQuantity': value.requiredSaleQuantity,
    };
}

