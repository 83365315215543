/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.30.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductDetailApiModelDeliveryTime = {
    Unknown: 'Unknown',
    Direct: 'Direct',
    Delayed: 'Delayed',
    NoDelivery: 'NoDelivery',
    StoreOnly: 'StoreOnly'
} as const;
export type ProductDetailApiModelDeliveryTime = typeof ProductDetailApiModelDeliveryTime[keyof typeof ProductDetailApiModelDeliveryTime];


export function ProductDetailApiModelDeliveryTimeFromJSON(json: any): ProductDetailApiModelDeliveryTime {
    return ProductDetailApiModelDeliveryTimeFromJSONTyped(json, false);
}

export function ProductDetailApiModelDeliveryTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDetailApiModelDeliveryTime {
    return json as ProductDetailApiModelDeliveryTime;
}

export function ProductDetailApiModelDeliveryTimeToJSON(value?: ProductDetailApiModelDeliveryTime | null): any {
    return value as any;
}

