import { DefaultConfig, ProductsApi } from '@groenhart/api';
import { ApiBase } from './base.api';

export function useProductsApi() {
  const runtimeConfig = useRuntimeConfig();
  const headers = useApiHeaders();

  const apiBase = new ApiBase(runtimeConfig.public.apiBase);
  DefaultConfig.config = apiBase.getDefaultConfig();

  const productsApi = new ProductsApi();

  async function getProduct(sku: string) {
    return await productsApi.productsSkuGet({ sku }, { headers });
  }

  async function getStock(sku: string) {
    return await productsApi.productsSkuStockGet({ sku }, { headers });
  }

  async function getProductCarousel() {
    return await productsApi.productsHomeInterestedGet({ headers });
  }

  async function getRecentlyOrdered() {
    return await productsApi.productsRecentlyOrderedGet({ headers });
  }

  async function getSku(input: string) {
    return await productsApi.productsGetSkuInputGetRaw({ input }, { headers });
  }

  return {
    getProduct,
    getStock,
    getProductCarousel,
    getRecentlyOrdered,
    getSku,
  };
}
